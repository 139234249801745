import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Container from '../components/shared/container'
import { mediaQueries } from '../theme'
import FooterCta from '../components/footer-cta'
import { Hr as BaseHr } from '../components/shared/rules'
import Layout from '../components/layout'
import PageMetadata from '../components/page-metadata'
import BaseLink from '../components/shared/link'
import { H1 } from '../components/shared/type'

const InnerContainer = styled(Container)`
  margin-bottom: ${p => p.theme.space[18]};
  padding: 0;

  ${mediaQueries.md} {
    margin-bottom: ${p => p.theme.space[29]};
  }
`

const Heading = styled(H1)`
  margin-bottom: ${p => p.theme.space[23]};
  padding: ${p => `0 ${p.theme.space[6]}`};

  ${mediaQueries.md} {
    padding: 0;
  }
`

const CategoryContainer = styled.div`
  padding: ${p => `0 ${p.theme.space[6]}`};

  ${mediaQueries.md} {
    width: max-content;
    padding: 0;
  }

  & + & {
    margin-top: ${p => p.theme.space[16]};
  }
`

const Header = styled.div`
  margin: ${p => `0 0 ${p.theme.space[6]}`};

  ${mediaQueries.md} {
    margin: ${p => `0 0 ${p.theme.space[6]}`};
  }
`

const Hr = styled(BaseHr)`
  margin: ${p => `0 0 ${p.theme.space[6]} 0`};
  padding: 0;

  ${mediaQueries.lg} {
    min-width: 24.25rem;
  }
`

const TitleLink = styled.span`
  margin: 0;
  font-weight: ${p => p.theme.fontWeights.heading};
  font-size: ${p => p.theme.fontSizes[6]};
  line-height: 1.5;

  ${mediaQueries.lg} {
    font-size: ${p => p.theme.fontSizes[5]};
  }
`

const Link = styled(BaseLink)`
  display: block;
  font-size: ${p => p.theme.fontSizes[2]};
  font-weight: ${p => p.theme.fontWeights.bold};
  color: ${p => p.theme.colors.text};

  ${mediaQueries.lg} {
    :hover {
      text-decoration: underline;
    }
  }
`

const InactiveLink = styled.span`
  font-size: ${p => p.theme.fontSizes[2]};
  font-weight: ${p => p.theme.fontWeights.bold};
  opacity: 0.3;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  padding: 0;
  list-style: ${p => (p.image ? `'${p.image} '` : 'none')};
`

const HelpPage = ({ data, location }) => {
  const {
    allMdx: { categories },
  } = data

  return (
    <Layout location={location}>
      <PageMetadata
        title="Help Center | Getform"
        description="Find answers to your questions about the Getform settings, features, and best practices."
      />
      <InnerContainer>
        <Heading>Help center</Heading>
        {categories.map(c => {
          const { posts, slug } = c
          return (
            <CategoryContainer key={slug}>
              <Header>
                <TitleLink>{posts[0].frontmatter.category}</TitleLink>
              </Header>

              <Hr />

              <List>
                {posts.map((post, index) => (
                  <ListItem key={`howto-${index}`}>
                    <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
                  </ListItem>
                ))}
              </List>
            </CategoryContainer>
          )
        })}

        <CategoryContainer>
          <Header>
            <TitleLink>General</TitleLink>
          </Header>

          <Hr />
          <InactiveLink>Coming soon</InactiveLink>
        </CategoryContainer>
      </InnerContainer>
      <FooterCta />
    </Layout>
  )
}

export default HelpPage

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { resource: { eq: "Help" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      categories: group(field: fields___categorySlug) {
        slug: fieldValue
        posts: nodes {
          ...postPreviewFragment
        }
      }
    }
  }
`
